<template>
  <div :style="disabled ? 'cursor: not-allowed;' : ''">
    <div v-if="preview" @click="closePreview" class="preview-screen">
      <div v-if="preview.image" class="preview-container">
        <p class="justify-self pt-1"><b>Creative</b></p>
        <div class="d-flex justify-content-center align-items-center h-100">
          <img @click.stop class="preview-image image-fade" :src="preview.image" alt="img">
        </div>
      </div>
      <div v-if="preview.html" class="preview-container m-preview">
        <p class="justify-self pt-1"><b>HTML Snippet</b></p>
        <p class="preview-html">{{ preview.html }}</p>
      </div>
      <div v-if="preview.url" class="preview-container">
        <p class="justify-self pt-1"><b>IFrame</b></p>
        <iframe class="preview-iframe" :src="preview.url" frameborder="0"></iframe>
      </div>
    </div>
    <div class="icon-form" :style="disabled ? 'opacity: 40%;' : ''">
      <div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Icon's name.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="iconName" type="text" placeholder="Icon Name"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template v-if="!disabled" slot="title">
            <span>Creative to use as a static resource for icon.</span>
          </template>
          <a-select :disabled="disabled" v-model="staticCreativeId" class="icon-form-item" placeholder="Select ID">
            <a-select-option :value="-1" disabled>Select Creative</a-select-option>
            <a-select-option label="Select Banner" v-for="obj in creatives" :key="obj.Id">
              [{{ obj.Id }}] {{ obj.Name }}
            </a-select-option>
          </a-select>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>URL of a resource that is an HTML page that can be displayed within an IFrame on the publisher's page.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="url" type="url" placeholder="IFrame URL"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>A "snippet" of HTML code to be inserted directly within the publisher's HTML page code.</span>
          </template>
          <!-- <a-input :disabled="disabled" class="icon-form-item" v-model="html" type="text" placeholder="HTML Snippet" /> -->
          <a-textarea
            :disabled="disabled"
            v-model="html"
            class="icon-form-item m-lg"
            placeholder="HTML Snippet"
          />
        </a-tooltip>
      </div>

      <div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Width of the placement slot for which the icon creative is intended.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="width" type="number" placeholder="Width"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Height of the placement slot for which the icon creative is intended.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="height" type="number" placeholder="Height"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>The horizontal alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the leftmost point of the ad display area.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="positionX" type="number"
                   placeholder="Position X"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>The vertical alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the topmost point of the ad display area.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="positionY" type="number"
                   placeholder="Position Y"/>
        </a-tooltip>
      </div>

      <div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>The amount of time in seconds, for which the video player should display the icon. If set to "0" (zero), the video player should display the icon while the ad is displayed or until the user interacts with the ad or the icon.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="duration" type="number" placeholder="Duration"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Number of seconds when the video player should display the icon after the creative begins playing.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="offsetSec" type="number"
                   placeholder="Offset Sec"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Identifies the industry initiative that the icon supports.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="program" type="text" placeholder="Program"/>
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Destination URL that loads when user clicks a companion ad.</span>
          </template>
          <a-input :disabled="disabled" class="icon-form-item" v-model="destinationUrl" type="url"
                   placeholder="Destination URL"/>
        </a-tooltip>
      </div>
      <div>
        <a-button class="mobile-submit-button" type="primary" @click="handleSubmit">Add +</a-button>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center"> -->
    <!-- <a-button class="ml-1 px-2 py-0" type="primary" @click="handleSubmit">Create Icon</a-button> -->
    <!-- </div> -->
    <!-- list -->
    <b-table
      :bordered="true"
      :fields="fields"
      :hover="true"
      :items="iconList"
      :small="true"
      :striped="true"
      :responsive="true"
      show-empty
      stacked="md"
      class="mt-4"
    >
      <template #cell(Preview)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="openPreview(data.item.StaticCreativeId, data.item.Html, data.item.IframeUrl)">
          <i class="fa fa-eye"></i>
        </b-button>
      </template>
      <template #cell(Name)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Icon's name.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Icon Name" v-model="data.item.Name" size="small"
                            @search="updateIcon(data.item.Id, 'Name', data.item, 'Name', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Icon's name.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Icon Name" v-model="data.item.Name" size="small"
                            @search="updateIcon(data.item.Id, 'Name', data.item, 'Name', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Html)="data">
        <div v-if="data.item.Html">
          <span style="fontSize: 90%;" class="material-icons-outlined">
            done
          </span>
        </div>
      </template>
      <template #cell(PositionX)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The horizontal alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the leftmost point of the ad display area.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Position X" v-model="data.item.PositionX"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Position X', data.item, 'PositionX', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The horizontal alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the leftmost point of the ad display area.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Position X" v-model="data.item.PositionX"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Position X', data.item, 'PositionX', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(PositionY)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The vertical alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the topmost point of the ad display area.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Position Y" v-model="data.item.PositionY"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Position Y', data.item, 'PositionY', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The vertical alignment location (in pixels) that the video player uses to place the top-left corner of the icon relative to the ad display area (not necessarily the video player display area). A value of "0" (zero) is the topmost point of the ad display area.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Position Y" v-model="data.item.PositionY"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Position Y', data.item, 'PositionY', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>

      <template #cell(Duration)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The amount of time in seconds, for which the video player should display the icon. If set to "0" (zero), the video player should display the icon while the ad is displayed or until the user interacts with the ad or the icon.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Duration" v-model="data.item.Duration"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Duration', data.item, 'Duration', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>The amount of time in seconds, for which the video player should display the icon. If set to "0" (zero), the video player should display the icon while the ad is displayed or until the user interacts with the ad or the icon.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Duration" v-model="data.item.Duration"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Duration', data.item, 'Duration', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(OffsetSec)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Number of seconds when the video player should display the icon after the creative begins playing.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Offset Sec" v-model="data.item.OffsetSec"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Offset Sec', data.item, 'OffsetSec', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Number of seconds when the video player should display the icon after the creative begins playing.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Offset Sec" v-model="data.item.OffsetSec"
                            type="number" size="small"
                            @search="updateIcon(data.item.Id, 'Offset Sec', data.item, 'OffsetSec', 'int')">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Program)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Identifies the industry initiative that the icon supports.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Program" v-model="data.item.Program"
                            type="text" size="small"
                            @search="updateIcon(data.item.Id, 'Program', data.item, 'Program', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Identifies the industry initiative that the icon supports.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Program" v-model="data.item.Program"
                            type="text" size="small"
                            @search="updateIcon(data.item.Id, 'Program', data.item, 'Program', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(DestinationUrl)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Destination URL that loads when user clicks a companion ad.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Destination URL" v-model="data.item.DestinationUrl"
                            type="text" size="small"
                            @search="updateIcon(data.item.Id, 'Destination URL', data.item, 'DestinationUrl', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Destination URL that loads when user clicks a companion ad.</span>
            </template>
            <a-input-search class="icon-input" placeholder="Destination URL" v-model="data.item.DestinationUrl"
                            type="text" size="small"
                            @search="updateIcon(data.item.Id, 'Destination URL', data.item, 'DestinationUrl', 'string')">
              <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>

      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="onDelete(data.item.Id)">
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
      <template #cell(StaticCreativeId)="data">
        <div v-if="data.item.StaticCreativeId">
          <span style="fontSize: 90%;" class="material-icons-outlined">
            done
          </span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import { createIcon, deleteIcon, updateIcon } from '@/api/display/icon'

export default {
  props: ['id', 'creatives', 'icons', 'labelCol', 'wrapperCol', 'disabled'],
  data() {
    return {
      iconName: null,
      staticCreativeId: -1,
      url: null,
      html: null,
      width: null,
      height: null,
      positionX: null,
      positionY: null,
      duration: null,
      offsetSec: null,
      program: null,
      destinationUrl: null,
      preview: false,
      iconList: this.icons,
      // creatives: [],
      fields: [
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 220px',
        },
        {
          key: 'StaticCreativeId',
          label: 'Creative',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Html',
          label: 'HTML',
          class: 'text-center',
          tdClass: 'align-middle',
          show: true,
          sortable: false,
          thStyle: 'width:50px',
        },
        {
          key: 'IframeUrl',
          label: 'IFrame URL',
          tdClass: 'text-md-left text-center',
          show: true,
          sortable: false,
        },
        {
          key: 'Width',
          label: 'Width',
          show: true,
          headerTitle: 'Zone ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'Height',
          label: 'Height',
          show: true,
          headerTitle: 'Zone ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'PositionX',
          label: 'Position X',
          show: true,
          headerTitle: 'Position X',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:100px',
        },
        {
          key: 'PositionY',
          label: 'Position Y',
          show: true,
          headerTitle: 'Position Y',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:100px',
        },
        {
          key: 'Duration',
          label: 'Duration (sec)',
          show: true,
          headerTitle: 'Duration',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:100px;',
        },
        {
          key: 'OffsetSec',
          label: 'Offset Sec',
          show: true,
          headerTitle: 'Offset Sec',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:100px;',
        },
        {
          key: 'Program',
          label: 'Program',
          show: true,
          headerTitle: 'Program',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:200px;',
        },
        {
          key: 'DestinationUrl',
          label: 'Destination URL',
          show: true,
          headerTitle: 'Destination URL',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:200px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
      ],
    }
  },
  methods: {
    convertMediaRating(val) {
      if (val === 1) {
        return 'All Audiences'
      } else if (val === 2) {
        return 'Everyone Over 12'
      } else if (val === 3) {
        return 'Mature Audiences'
      } else {
        return undefined
      }
    },
    openPreview(id, html, url) {
      const found = this.creatives.find((el) => el.Id === id)
      if (found !== undefined) {
        this.preview = {
          image: found.Cdn,
          html: html,
          url: url,
        }
        this.preview.image = found.Cdn
      } else {
        this.preview = {
          html: html,
          url: url,
        }
      }
    },
    closePreview() {
      this.preview = false
    },
    handleChange(val, e) {
      this.val = e.target.value
    },
    updateIcon(id, placeholder, item, key, type) {
      if (item !== null) {
        const payload = {
          Id: id,
          Name: item.Name,
        }
        if (type === 'int') {
          payload[key] = parseInt(item[key])
        } else if (type === 'string') {
          payload[key] = item[key]
        }
        updateIcon(payload)
        Vue.prototype.$notification.success({
          message: 'Icon Updated.',
          description: `Icon: ${item.Name} Updated.`,
        })
      } else {
        Vue.prototype.$notification.warning({
          message: `${placeholder} is required.`,
        })
      }
    },
    updateIconName(Id, Name) {
      // MediaRating = parseInt(MediaRating)
      if (Name !== null) {
        const payload = {
          Id,
          Name,
        }
        updateIcon(payload)
        Vue.prototype.$notification.success({
          message: 'Icon Updated.',
          description: `Icon: ${Name} Updated.`,
        })
        Vue.prototype.$notification.success({
          message: 'Icon Updated.',
          description: `Icon: ${Name} Updated.`,
        })
      } else {
        Vue.prototype.$notification.warning({
          message: 'Name is required.',
        })
      }
    },
    updateIconDuration(Id, Name, Duration) {
      // MediaRating = parseInt(MediaRating)
      const payload = {
        Id,
        Duration,
      }
      updateIcon(payload)
      Vue.prototype.$notification.success({
        message: 'Icon Updated.',
        description: `Icon: ${Name} Updated.`,
      })
    },
    updateMediaRating(Id, Name, MediaRating) {
      MediaRating = parseInt(MediaRating)
      const payload = {
        Id,
        Name,
        MediaRating,
      }
      updateIcon(payload)
      Vue.prototype.$notification.success({
        message: 'Icon Updated.',
        description: `Icon: ${Name} Updated.`,
      })
    },
    onDelete(Id) {
      Modal.confirm({
        title: 'Are you sure you want to delete this icon?',
        onOk: () => {
          deleteIcon(Id)
          const index = this.iconList.findIndex(el => el.Id === Id)
          this.iconList.splice(index, 1)
          // this.iconList.splice(Id, 1)
        },
      })
    },
    handleSubmit() {
      if ((this.iconName !== null && this.width !== null && this.height !== null && this.program !== null && this.destinationUrl !== null) && (this.staticCreativeId !== null || this.html !== null || this.url !== null)) {
        // if (this.staticCreativeId === -1) {
        //   this.staticCreativeId = null
        // }
        const values = {
          VideoadId: this.id,
          Name: this.iconName,
          StaticCreativeId: this.staticCreativeId === -1 ? null : this.staticCreativeId,
          IframeUrl: this.url,
          Html: this.html,
          Width: this.width,
          Height: this.height,
          PositionX: this.positionX,
          PositionY: this.positionY,
          Duration: this.duration,
          OffsetSec: this.offsetSec,
          Program: this.program,
          DestinationUrl: this.destinationUrl,
        }
        createIcon(values).then((res) => {
          if (res.Icon) {
            this.iconList.push(res.Icon)
            this.iconName = null
            this.staticCreativeId = -1
            this.url = null
            this.html = null
            this.width = null
            this.height = null
            this.positionX = null
            this.positionY = null
            this.duration = null
            this.offsetSec = null
            this.program = null
            this.destinationUrl = null
          }
        })
      } else {
        this.staticCreativeId = this.staticCreativeId !== -1 || this.staticCreativeId !== null ? this.staticCreativeId : -1
        Vue.prototype.$notification.warning({
          message: 'Not enough information.',
          description: 'Icon Name, Width, Height, Program and Destination URL are required.',
        })
      }
    },
  },
  watch: {
    staticCreativeId(val) {
      this.creatives.forEach(element => {
        if (element.Id === val) {
          this.width = element.Width
          this.height = element.Height
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/custom/icons/partials.scss';

.ant-form-item {
  margin-bottom: 0px;
}

@media (min-width: 1208px) {
  .m-lg {
    height: 30px !important;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 1208px) {
  .m-lg {
    margin-bottom: 1em !important;
  }
}
</style>
